<template>
  <div class="localInfo">
    <el-row class="localInfo-tab" type="flex" justify="space-between">
      <el-col :span="20">
        <tab-menu :item="item" @select="handleSelect"></tab-menu>
      </el-col>
      <el-col :span="4" class="right">
        <pagination
          v-if="activeIndex == 0 && total != 0"
          :layout="'pager'"
          :total="total"
          :pageSize="1"
          :currentPage="pageIndex + 1"
          @changePage="handlePageChange"
        ></pagination>
      </el-col>
    </el-row>

    <div class="info">
      <matter-info
        :matterData="matterData"
        v-if="activeIndex == 0 && total != 0"
      ></matter-info>
      <empty v-else></empty>
    </div>
  </div>
</template>

<script>
import MatterInfo from "@/components/matter";
import Pagination from "@/components/paginations";
import Empty from "@/components/empty";
import TabMenu from "@/components/tab_menus";
import { getMatterList, crmGetCustomList } from "@/api/crm/resource_library";
export default {
  data() {
    return {
      // item: ["招商事项台帐", "招商项目", "签约项目管理"],
      item:[
        {
          name:'招商事项台账'
        },
        {
          name:'招商项目'
        },{
          name:'签约项目管理'
        }
      ],
      activeIndex: 0,
      total: 1,
      pageIndex: 0,
      matterData: new Object(),
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  components: {
    MatterInfo,
    Pagination,
    Empty,
    TabMenu,
  },
  mounted() {
    this.getMatterList();
  },
  methods: {
    handleSelect(i) {
      this.activeIndex = i;
    },
    getMatterList() {
      getMatterList("1/10", { resId: this.id, itemType: 0 }).then((res) => {
        this.total = res.data.length;
        if (this.total > 0) {
          this.matterList = res.data;
          this.$set(this.matterData, "matterInfo", {
            ...this.matterList[this.pageIndex],
          });
          this.getCustomList();
        }
      });
    },
    getCustomList() {
      crmGetCustomList("1", this.matterData.matterInfo.id).then((res) => {
        if (res.status == 200) {
          this.$set(this.matterData, "lstCutomer", res.data);
        }
      });
    },
    handlePageChange(val) {
      this.pageIndex = val - 1;
       this.$set(this.matterData, "matterInfo", {
            ...this.matterList[this.pageIndex],
          });
      this.getCustomList();
    },
  },
};
</script>

<style lang="scss" scoped>
.localInfo {
  background: #fff;
}
.info {
  padding: 41px 78px 0;
}
.el-menu.el-menu--horizontal {
  border-bottom: 0;
}
.localInfo-tab {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 40px;
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
#empty {
  min-height: 400px;
}
::v-deep .el-pager li {
  margin-right: 0;
}
</style>