<template>
  <div>
    <el-row
      type="flex"
      justify="space-between"
      :gutter="20"
      class="company-box"
    >
      <el-col class="el-left" :span="18">
        <div class="company-title">{{ info.ENTNAME }}</div>
        <div class="company-info">
          <el-row>
            <el-col :span="8">联系电话：{{ info.TEL }}</el-col>
            <el-col :span="10">邮箱：{{ info.EMAIL }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8"
              >公司网址：
              <a :href="info.WEBURL" target="_blank" class="active-color">{{
                info.WEBURL
              }}</a>
            </el-col>
            <el-col :span="16">通讯地址：{{ info.DOM }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="24">经营范围：{{ info.OPSCOPE }}</el-col>
          </el-row>
        </div>
        <div class="company-important-info">
          <el-row type="flex" align="middle">
            <el-col :span="3">
              <div class="lable">法定代表人</div>
              <div class="value">{{ info.FRDB }}</div>
            </el-col>
            <el-col :span="1">
              <div class="borderRight"></div>
            </el-col>
            <el-col :span="3"
              ><div class="lable">注册资本</div>
              <div class="value">{{ info.REGCAP }}</div>
            </el-col>
            <el-col :span="1">
              <div class="borderRight"></div>
            </el-col>
            <el-col :span="3"
              ><div class="lable">成立日期</div>
              <div class="value">{{ info.ESDATE }}</div></el-col
            >
          </el-row>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="company-status">
          <div>{{ eva_desc[info.OVERALL_GRADE] }}</div>
        </div>
        <div class="company-appraise">
          <div class="company-appraise-title">综合评价</div>
          <el-row
            type="flex"
            justify="space-around"
            class="company-appraise-info"
          >
            <el-col>
              <div class="company-appraise-value">{{ info.OVERALL_GRADE }}</div>
              <div class="company-appraise-label">评价等级</div>
            </el-col>
            <el-col>
              <div class="company-appraise-value">{{ info.OVERALL }}</div>
              <div class="company-appraise-label">综合评分</div>
            </el-col>
          </el-row>
        </div>
        <div class="company-power">能力评价（{{ info.UPDATE }}）</div>
      </el-col>
    </el-row>
    <tab-menu
      :item="tab_item"
      :activeIndex="activeIndex"
      @select="handleSelect"
    ></tab-menu>
    <div class="tab-info">
      <localInfo :id="$route.params.id" v-if="activeIndex == 1"></localInfo>
      <empty v-else></empty>
    </div>
  </div>
</template>

<script>
import { crmEntDetail, crmEntInfoDetail } from "@/api/crm/resource_library";
import localInfo from "./localInfo";
import TabMenu from "@/components/tab_menus";
import Empty from "@/components/empty";
export default {
  data() {
    return {
      info: {},
      companyName: "",
      activeIndex: 1,
      // tab_item: ["基本信息", "本地信息"],
      tab_item:[
        {
          name:'基本信息'
        },
        {
          name:'本地信息'
        }
      ],
      eva_desc: {
        CCC: "风险偏高",
        CC: "风险高",
        C: "风险极高",
        BBB: "比较健康",
        BB: "一般",
        B: "风险偏高",
        AAA: "非常健康",
        AA: "健康",
        A: "健康",
      },
    };
  },
  watch: {
    $route(newVal) {
      if (newVal.name == "resourceCompany") {
        this.getByEntName();
      }
    },
  },
  components: {
    localInfo,
    TabMenu,
    Empty,
  },
  mounted() {
    this.getByEntName();
  },
  methods: {
    handleSelect(i) {
      this.activeIndex = i;
    },
    // 获取企业名称
    getByEntName() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      crmEntDetail(`${this.$route.params.id}`)
        .then((res) => {
          if (res.status == 200) {
            var index = this.$store.state.menu.active_menus.findIndex(
              (x) => x.path == this.$route.path
            );
            this.$store.dispatch("setTitle", {
              value: res.data.entName,
              index: index,
            });
            this.companyName = res.data.entName;
          }
          // loading.close();
          this.getByEntInfo();
        })
        .catch((error) => {
          this.loading.close();
        });
    },
    // 获取企业基本信息
    getByEntInfo() {
      crmEntInfoDetail("getEntByKeyword", {
        keyword: this.companyName,
        type: 0,
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data[0].WEBURL) {
              if (res.data[0].WEBURL.indexOf("http") == -1) {
                res.data[0].WEBURL = "http://" + res.data[0].WEBURL;
              }
            }
            this.info = { ...this.info, ...res.data[0] };
          }
        })
        .catch((error) => {
          this.loading.close();
        });
      crmEntInfoDetail("getRegisterInfo", {
        entName: this.companyName,
      })
        .then((res) => {
          if (res.status == 200) {
            this.$set(this.info, "OPSCOPE", res.data[0]["OPSCOPE"]);
          }
        })
        .catch((error) => {
          this.loading.close();
        });
      crmEntInfoDetail("getEntEvaluatingIndexInfo", {
        entName: this.companyName,
      })
        .then((res) => {
          if (res.status == 200) {
            this.info = { ...this.info, ...res.data[0] };
            this.loading.close();
          }
        })
        .catch((error) => {
          this.loading.close();
        });
    },

    // 获取经营范围
    // getRegisterInfo
  },
};
</script>

<style lang="scss" scoped>
.company-box {
  width: 100%;
  padding: 24px 30px;
  box-sizing: border-box;
  .el-left {
    margin-right: 70px;
  }
  .company-title {
    font-size: 20px;
    color: #374567;
    margin-bottom: 18px;
  }
  .company-info {
    font-size: 14px;
    color: rgba(55, 69, 103, 0.85);
    .el-row {
      margin-bottom: 8px;
    }
  }
  .company-important-info {
    width: 100%;
    font-size: 14px;
    color: rgba(55, 69, 103, 0.85);
    padding: 7px 0;
    box-sizing: border-box;
    border-bottom: 1px dotted #e1e6f0;
    border-top: 1px dotted #e1e6f0;
    margin-top: 20px;
    .el-col {
      //   border-right: 1px solid #e1e6f0;
      text-align: center;
      width: auto;
      padding: 0 24px;
    }
    .value {
      color: rgb(55, 69, 103);
      margin-top: 5px;
    }
    .borderRight {
      border-right: 1px solid #e1e6f0;
      height: 30px;
    }
  }
  .company-status {
    font-size: 32px;
    color: #8ec31f;
    text-align: center;
    font-weight: 500;
  }
  .company-appraise {
    .company-appraise-title {
      font-size: 20px;
      color: rgba(55, 69, 103, 0.45);
      font-weight: 400;
      text-align: center;
      margin-bottom: 18px;
    }
  }
  .company-appraise-info {
    text-align: center;
    .company-appraise-value {
      font-size: 20px;
      color: #8ec31f;
    }
    .company-appraise-label {
      font-size: 14px;
      color: rgba(55, 69, 103, 0.45);
    }
  }
  .company-power {
    font-size: 14px;
    color: rgba(55, 69, 103, 0.45);
    text-align: center;
    margin-top: 12px;
    font-weight: 400;
  }
}
.tab-info {
  background: #f3f7ff;
  padding: 24px;
}
.el-menu {
  padding-left: 40px;
}
#empty {
  min-height: 400px;
  background: #fff;
}
</style>